import React from "react";

function Body() {
  return (
    <>
      <h1 style={{ marginBottom: "50px" }}>
        Url Shortner By {""}
        <a
          style={{ textDecoration: "none", color: "#e5e5e5" }}
          href="http://github.com/vats1812"
          target="_blank"
          rel="noopener noreferrer"
        >
          Vats1812
        </a>
      </h1>
    </>
  );
}

export default Body;
