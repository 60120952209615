import "./App.css";
import Background from "./Components/Background/Background";
import Body from "./Components/Body/Body";
import ShortnerBody from "./Components/ShortnerBody/ShortnerBody";

function App() {
  return (
    <>
      <div className="App">
        <Background />
        <div className="body">
          <Body />
          <ShortnerBody />
        </div>
      </div>
    </>
  );
}

export default App;
