import React, { useState } from "react";
import axios from "axios";
import { Button, Input, Space } from "antd";
import "./ShortnerBody.css";
import Result from "../Result/Result";
function ShortnerBody() {
  const [urlValue, setUrlValue] = useState("");
  const [result, setResult] = useState({});

  const handleGenerate = async () => {
    await axios
      .post("https://url-shortner.vatspatel.in/api/short-url", {
        actualUrl: urlValue,
      })
      .then((res) => {
        setResult(res.data);
      })
      .catch((err) => {
        alert(err?.response?.data?.error);
      });
  };
  return (
    <>
      <Space.Compact
        style={{
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Input
          placeholder="Enter Your URL"
          onChange={(value) => {
            setUrlValue(value.target.value);
          }}
          required="true"
        />
        <Button type="primary" onClick={handleGenerate}>
          Generate
        </Button>
      </Space.Compact>
      <Result success={result.success} shortId={result.id ? result.id : ""} />
    </>
  );
}

export default ShortnerBody;
