import React, { useState } from "react";
import { CopyFilled } from "@ant-design/icons";
import "./Result.css";
import { Tooltip } from "antd";
function Result({ status, shortId }) {
  let shortUrl = "http://url-shortner.vatspatel.in/" + shortId;
  const [colorState, setColorState] = useState("#d5d5d5");
  return (
    <>
      <div className="result-body">
        <h2>Generated URL: </h2>
        <div className="generated">
          <a
            style={{ textDecoration: "none", color: "#e5e5e5" }}
            href={shortUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {shortUrl}
          </a>
          <Tooltip
            title="Copied to Clipboard!!"
            trigger="click"
            color="#44ff44"
            style={{ color: "#000" }}
          >
            <CopyFilled
              onClick={() => {
                navigator.clipboard.writeText(shortUrl);
                setColorState("#44ff44");
                setTimeout(() => {
                  setColorState("#d5d5d5");
                }, 1000);
              }}
              color={colorState}
              style={{
                marginLeft: "10px",
                cursor: "pointer",
                color: colorState,
              }}
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
}

export default Result;
